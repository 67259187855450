@charset "UTF-8";

$base-font-family: -apple-system, Helvetica, Arial, sans-serif;
$base-font-family: Sentinel SSm A,Sentinel SSm B,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;
$base-font-size:   1.2rem;
$base-line-height: 1.7rem;
$link-color: orange;
$link-hover: orangered;
$link-color: #999;
$link-hover: #ccc;

/* light theme color */
$light-background: #fff;
$light-background-secondary: #eaeaea;
$light-color: #222;
$light-color-secondary: #999;
$light-border-color: #dcdcdc;
$light-logo-color: orange;

/* dark theme colors */
$dark-background: #222;
$dark-background: #eee;
$dark-background: #333;
$dark-background-secondary: #555;
$dark-background-secondary: #aaa;
$dark-background-secondary: #ccc;
$dark-color: #e9e9e9;
$dark-color: #555;
$dark-color: #ddd;
$dark-color: #ccc;
$dark-color-secondary: #73747b;
$dark-color-bright: #000;
$dark-border-color: #444;
$dark-pre-background: #212020;
//$dark-pre-background: #111;
$dark-logo-color: greenyellow;
$dark-logo-color: #666;

$media-size-phone: "(max-width: 684px)";
$media-size-tablet: "(max-width: 900px)";

/* variables for js, must be the same as these in @custom-media queries */
:root {
  --phoneWidth: (max-width: 684px);
  --tabletWidth: (max-width: 900px);
}
