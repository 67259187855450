.header {
  background: $light-background-secondary;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 0;

  .dark-theme & {
    background: #fff;
    background: $dark-background-secondary;
    background: #eee;
  }

  &__right {
    display: flex;
    flex-direction: row;
    align-items: center;

    @media #{$media-size-phone} {
      flex-direction: row-reverse;
    }
  }
  a {
    font-size: .8rem;
  }

  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    padding-left: 2rem;
    padding-right: 2rem;
    width: 800px;
    max-width: 100%;
    background: $dark-background-secondary;
  }
}

.theme-toggle {
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  cursor: pointer;
  color: $dark-background;
}

.theme-toggler {
  fill: currentColor;
}

.unselectable {
  user-select: none;
  -webkit-user-select: none;  
  -moz-user-select: none;    
  -ms-user-select: none;      
}
