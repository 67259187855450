.logo {
  display: flex;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: bold;
  font-display: auto;
//  font-style: italic;
  font-family: Exo, sans-serif;
  padding-bottom: .5rem;
  padding-top: .5rem;

  &__text {
    font-size: 2rem;
    line-height: 1.5rem;
    color: $light-logo-color;

    .dark-theme & {
      color: $dark-logo-color;
    }
  }

}
