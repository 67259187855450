.posts {
  width: 100%;
  max-width: 800px;
  text-align: left;
  padding: 25px;
  margin: 0 auto;

  @media #{$media-size-tablet} {
    max-width: 660px;
  }

  &:not(:last-of-type) {
    border-bottom: 1px solid $light-border-color;

    .dark-theme & {
      border-color: $dark-border-color;
    }
  }

  &-group {
    display: flex;
    margin-bottom: 1.9rem;
    line-height: normal;

    @media #{$media-size-tablet} {
      display: block;
    }
  }

  &-list {
    flex-grow: 1;
    margin: 0;
    padding: 0;
    list-style: none;
    .category {
      text-transform: capitalize;
      padding-top: 2rem;
    }
  }

  .post {
    &-title {
      font-size: 1.7rem;
      line-height: 2.5rem;
      margin: 5px 0 5px 0;
    }

    &-year {
      padding-top: 6px;
      margin-right: 1.8rem;
      font-size: 1.6rem;
      @include dimmed;

      @media #{$media-size-tablet} {
        margin: -6px 0 4px;
      }
    }

    &-item {
      border-bottom: 0px grey solid;
      padding: .2rem 0;

      a {
        display: box;
        justify-content: space-between;
        align-items: baseline;
        padding: 0rem 0;
        text-decoration: underline;
      }
    }

    &-day {
      float: right;
      width: 8rem;
      margin-left: 1rem;
      @include dimmed;
    }
  }
}
